.quotation-slider {
    display: flex;
    overflow: hidden;
}

.quotation {
    flex: 0 0 100%;
    transition: transform 1s ease-in-out;
}

.quotation.active {
    flex: 0 0 100%;
}

.interview-supporter-home .time-slot-container { 
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 10px; /* Adjust the gap between buttons */
    padding: 5px; /* Add padding around the container */
}

.interview-supporter-home .time-slot-button {
    border: 0.3px solid rgb(108, 103, 103); /* Remove default border */
    padding: 5px 10px; /* Adjust padding */
    border-radius: 5px; /* Rounded corners */ 
}