.admin-completed-trainings .monthly-search-btn {
    font-size: medium !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important; 
}

.admin-completed-trainings .view-btn {
    font-size: small !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
}

.cdb-datatable thead[data-test="table-foot"] {
    display: none; /* Hide the footer <thead> */
}