.quotation-slider {
    display: flex;
    overflow: hidden;
}

.quotation {
    flex: 0 0 100%;
    transition: transform 1s ease-in-out;
}

.quotation.active {
    flex: 0 0 100%;
}

.candidate-home-interview-support-bookings .time-slot-container { 
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 10px; /* Adjust the gap between buttons */
    padding: 5px; /* Add padding around the container */
}

.candidate-home-interview-support-bookings .time-slot-button {
    border: 0.3px solid rgb(108, 103, 103); /* Remove default border */
    padding: 5px 10px; /* Adjust padding */
    border-radius: 5px; /* Rounded corners */
}

.candidate-home-job-support-bookings .time-slot-container { 
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 10px; /* Adjust the gap between buttons */
    padding: 5px; /* Add padding around the container */
}

.candidate-home-job-support-bookings .time-slot-button {
    border: 0.3px solid rgb(108, 103, 103); /* Remove default border */
    padding: 5px 10px; /* Adjust padding */
    border-radius: 5px; /* Rounded corners */
}

/* popup css */
.candidate-flows-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 20px 0; /* Adjust vertical spacing */
}

.candidate-flow-popup-link {
    padding: 0;
    border: none;
    background: none;
    color: var(--app-color); /* Customize this variable as needed */
    text-decoration: underline;
    cursor: pointer;
    display: inline-block;
    margin: 10px; /* Adjust margin as needed */
    white-space: nowrap; /* Ensure links do not wrap */
}

@media (max-width: 768px) {
    .candidate-flows-container {
        flex-direction: column;
    }

    .candidate-flow-popup-link {
        margin: 5px 0; /* Adjust margin as needed */
    }
}

.modal-lg {
    max-width: 80%; /* Adjust the width as needed */
}

.candidate-flow-popup-item {
    white-space: nowrap; /* Ensure points do not wrap */
    padding: 5px 0; /* Add padding between points */
}







