.training-list .card:hover {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
}

.training-list .course-multiselect .option:hover {
    background-color: var(--child-color) !important;
}

.training-list .course-multiselect .search-button-horizontal {
    font-size: medium !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}

.training-list .prev-next-div {
    display: flex;
    justify-content: flex-end;
    padding-right: 10%;
}

.training-list .prev-next-div button[disabled] {
    background-color: #8CB9BD !important;
    border-color: #8CB9BD !important;
}

.training-list .enroll-button {
    font-size: medium !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}
