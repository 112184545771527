.admin-today-job-support-bookings .view-btn {
    font-size: small !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
}

.view-today-job-support-booking-modal .time-slot-container { 
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to the next line */
    gap: 10px; /* Adjust the gap between buttons */
    padding: 5px; /* Add padding around the container */
}

.view-today-job-support-booking-modal .time-slot-button {
    border: 0.3px solid rgb(108, 103, 103); /* Remove default border */
    padding: 5px 10px; /* Adjust padding */
    border-radius: 5px; /* Rounded corners */
}

.view-today-job-support-booking-modal .upload-meeting-link-btn {
    font-size: medium !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
}

.view-today-job-support-booking-modal .remove-meeting-link-btn {
    font-size: medium !important;
    background-color: rgb(224, 161, 44) !important;
    border-color: rgb(224, 161, 44) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
}

.view-today-job-support-booking-modal .stop-btn {
    font-size: medium !important;
    background-color: rgb(123, 32, 14) !important;
    border-color: rgb(123, 32, 14) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
}

.stop-job-support-booking-modal .stop-btn {
    font-size: medium !important;
    background-color: rgb(123, 32, 14) !important;
    border-color: rgb(123, 32, 14) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
}

.view-today-job-support-booking-modal button[disabled] {
    background-color: #8CB9BD !important;
    border-color: #8CB9BD !important;
}

.cdb-datatable thead[data-test="table-foot"] {
    display: none; /* Hide the footer <thead> */
}

.view-today-job-support-booking-modal .project-description {
    display: block;
    max-height: 60px;
    max-width: 1000px;
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    white-space: normal;
    /* Adjust width as needed */
}