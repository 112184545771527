/* Style for table */
.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed; /* Ensures consistent column width */
    border: 2px solid #ccc; /* Adds a border around the table */
}

/* Header cells */
.styled-table th {
    padding: 12px;
    border-right: 2px solid #ccc; /* Vertical border between header columns */
    border-bottom: 2px solid #ccc; /* Bottom border below header */
    background-color: #f1f1f1;
    font-weight: bold;
    text-align: left;
    color: #333;
}

/* Data cells */
.styled-table td {
    padding: 10px;
    border-right: 1px solid #ccc; /* Vertical border after each data cell */
    border-bottom: 1px solid #ccc; /* Bottom borders between rows */
    word-wrap: break-word; /* Prevents text overflow */
    text-align: left;
}

/* Row separator */
.styled-table tr {
    border-bottom: 2px solid #ccc;
}

/* Alternate row coloring for better readability */
.styled-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Hover effect on rows */
.styled-table tr:hover {
    background-color: #e0e0e0;
}

/* Button styles */
button {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
}

/* Active & Inactive buttons */
button.active-btn {
    background-color: #007bff;
    color: #fff;
}

button.inactive-btn {
    background-color: #ccc;
    color: #333;
}

/* Hover effects */
button:hover {
    opacity: 0.8;
}

/* Error message */
.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Success message */
.success-message {
    color: green;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Search container */
.search-container {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

/* Search input */
.search-container input {
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-right: 10px;
    width: 250px;
}

/* Search button */
.search-container button {
    background-color: #007bff;
    color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
    .styled-table th,
    .styled-table td {
        padding: 8px;
        font-size: 12px;
    }

    .search-container {
        flex-direction: column;
    }

    .search-container input {
        width: 100%;
        margin-bottom: 10px;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .modal-content {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        width: 300px;
        text-align: center;
    }
    
    button {
        padding: 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    
    /* In your CSS file */
/* Success Message */
.success-message {
    color: green;               /* Green text color */
    background-color: #e0f7e0;  /* Light green background */
    padding: 10px 20px;         /* Padding around the message */
    margin-bottom: 10px;        /* Margin at the bottom */
    font-weight: bold;          /* Bold font weight */
    border-radius: 5px;         /* Rounded corners */
    border: 1px solid green;    /* Green border */
    box-shadow: 0 0 5px rgba(0, 128, 0, 0.2);  /* Optional: light shadow effect */
}


}