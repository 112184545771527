/* Ensure labels are not bold */
.role label {
    font-weight: normal;
}

/* Ensure the input field for role name matches the dropdown width */
.add-role-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: var(--white-color) !important;
    background-clip: padding-box;
    border: 1px solid #94989d;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    max-width: 400px;
}

/* Add Button Styles */
.add-role-button-container button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
}

.add-role-button-container button:hover {
    background-color: #45a049;
}

/* View Roles Dropdown Styles */
.view-role select {
    width: 100%;
    max-width: 400px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* For the message area with error or success */
.add-role .errMsg {
    font-size: medium;
    font-weight: bold;
    color: red;
}

/* Editable role input */
.edit-role input {
    width: 100%;
    max-width: 400px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Edit and Save buttons */
.edit-button, .save-button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    margin-top: 8px;
}

.edit-button:hover, .save-button:hover {
    background-color: #45a049;
}
