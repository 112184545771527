/* Course CSS */

/* Create Course CSS */
.course .create-course .create-course-input {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: var(--white-color) !important;
    background-clip: padding-box;
    border: 1px solid #94989d;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    max-width: 400px;
}

.course .create-course .create-course-input:focus {
    outline: 0;
}