.admin-search-users .option:hover {
    background-color: var(--child-color) !important;
}

.admin-search-users .search-button-horizontal {
    font-size: medium !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}