.candidate-interview-support-bookings .card:hover {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.5);
}

.candidate-interview-support-bookings .time-slot-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
    gap: 10px;
    /* Adjust the gap between buttons */
    padding: 5px;
    /* Add padding around the container */
}

.candidate-interview-support-bookings .time-slot-button {
    border: 0.3px solid rgb(108, 103, 103);
    /* Remove default border */
    padding: 5px 10px;
    /* Adjust padding */
    border-radius: 5px;
    /* Rounded corners */
}

.candidate-interview-support-bookings .booking-status-pending-button {
    font-size: medium !important;
    font-weight: 600;
    background-color: rgb(224, 161, 44);
    border-color: rgb(224, 161, 44);
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}

.candidate-interview-support-bookings .booking-status-pending-button i {
    margin-right: 8px;
}

.candidate-interview-support-bookings .booking-status-confirmed-button {
    font-size: medium !important;
    font-weight: 600;
    background-color: rgb(49, 148, 49);
    border-color: rgb(49, 148, 49);
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}

.candidate-interview-support-bookings .booking-status-confirmed-button i {
    margin-right: 8px;
}

.candidate-interview-support-bookings .cancel-booking-button {
    font-size: small !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}

.candidate-interview-support-bookings .reschedule-booking-button {
    font-size: small !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-left: 20px;
}

.view-selected-reschedule-interview-support-modal .time-slot-container {
    display: flex;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
    gap: 10px;
    /* Adjust the gap between buttons */
    padding: 5px;
    /* Add padding around the container */
}

.view-selected-reschedule-interview-support-modal .time-slot-button {
    border: 0.3px solid rgb(108, 103, 103);
    /* Remove default border */
    padding: 5px 10px;
    /* Adjust padding */
    border-radius: 5px;
    /* Rounded corners */
    cursor: pointer;
    /* Pointer cursor on hover */
    transition: background-color 0.3s ease;
    /* Smooth background color transition */
}

.view-selected-reschedule-interview-support-modal .time-slot-button:hover {
    background-color: var(--child-color) !important;
    /* Button background color on hover */
    border: none;
}

.time-slot-button.selected {
    background-color: var(--app-color) !important;
    /* Different color for selected buttons */
    border: none;
    color: var(--white-color) !important;
}

@media (max-width: 768px) {
    .view-selected-reschedule-interview-support-modal .time-slot-button {
        width: 100%;
        /* Make buttons full-width on smaller screens */
    }
}

.view-selected-reschedule-interview-support-modal .label-container {
    display: flex;
    justify-content: space-between;
    /* Space out the label and the icon */
    align-items: center;
    /* Center align the label and icon vertically */
}

.view-selected-reschedule-interview-support-modal .reload-container {
    display: flex;
    align-items: center;
    /* Center align the icon and text vertically */
    cursor: pointer;
    /* Pointer cursor on hover */
}

.view-selected-reschedule-interview-support-modal .reload-icon {
    color: var(--app-color) !important;
    /* Color of the reload icon */
    transition: color 0.3s ease;
    /* Smooth color transition */
}

.view-selected-reschedule-interview-support-modal .reload-text {
    margin-left: 5px;
    /* Space between icon and text */
    color: var(--app-color) !important;
    /* Color of the text */
    transition: color 0.3s ease;
    /* Smooth color transition */
}

.view-selected-reschedule-interview-support-modal .modal-button {
    font-size: medium !important;
    background-color: var(--app-color) !important;
    border-color: var(--app-color) !important;
    color: var(--white-color) !important;
    border-radius: 4px !important;
    margin-top: 10px;
}

.candidate-interview-support-bookings .job-description {
    display: block;
    max-height: 60px;
    max-width: 1000px;
    /* Set a fixed height */
    overflow-y: auto;
    /* Enable vertical scrolling */
    white-space: normal;
    /* Adjust width as needed */
}